<template>
	<div class="container" id="container">
		<div style="position: relative;display: flex;">
			<div class="explain_Answer1 col-md-1">
				<div class="aboust_bott20">
					<p class="explain_right">
						<span @click="startRecording" v-if="!videoStart">{{$store.state.teachlanguagedata.StartRecording}}</span>
						<span @click="stopRecording" v-if="videoStart" id="btn-stop-recording">{{$store.state.teachlanguagedata.EndRecording}}</span>
					</p>
					<p class="explain_right" @click="shangyyiye()">上一页 </p>
					<p class="explain_right" @click="xiayiye()">下一页 </p>
					<p class="explain_right" @click="explain_return()">返回 </p>
					<p class="explain_right" @click="canves_show=true">{{$store.state.teachlanguagedata.comments}}</p>
				</div>
			</div>
			<div class="container_con col-md-10 overflowAuto height85vh" >
				<PointDataBgc ref="PointDataBgc" v-if="renderComponent">
				</PointDataBgc>
				<!-- <canvas id="canvasMicro" :width="canvesWight" :height="canvesHeight"></canvas> -->
			</div>
			<div class="explain_Answer2  col-md-1">
				<div class="aboust_bott20">
					<p class="explain_right">
						<span @click="startRecording" v-if="!videoStart">{{$store.state.teachlanguagedata.StartRecording}}</span>
						<span @click="stopRecording" v-if="videoStart" id="btn-stop-recording">{{$store.state.teachlanguagedata.EndRecording}}</span>
					</p>
					<p class="explain_right" @click="shangyyiye()">上一页 </p>
					<p class="explain_right" @click="xiayiye()">下一页 </p>
					<p class="explain_right" @click="explain_return()">返回 </p>
					<p class="explain_right" @click="canves_show=true">{{$store.state.teachlanguagedata.comments}}</p>
				</div>
			</div>
		</div>
		<Board v-if="canves_show" v-on:close="close"></Board>
		<screen-recording ref="screen-recording" @streamStop="streamStop" :fileName="fileName" :planIdMicro="$route.params.plan_id"></screen-recording>
	</div>
</template>

<script>
	import PointDataBgc from '../../components/ComponentPage/PointDataBgc.vue'
	import screenRecording from '../../components/screen-recording';
	import Board from '../../components/CanvasBoard/index.vue'
	import {
		queryDetailById
	} from '../../api/Collect.js'
	import {queryMacList} from '../../api/base.js'
	export default {
		name: 'Person',
		components: {
			PointDataBgc,screenRecording,Board
		},
		computed: {
			
		},
		watch: {
			
		},
		data() {
			return {
				classItem:{
					penMac:''
				},
				fileName:Date.parse(new Date())+'录屏',
				videoStart:false,
				renderComponent:true,
				canvesWight: 1033.4,
				canvesHeight: 1461.6,
				img_list_index:0,
				pageIds:[],
				queryDetailByIdData:[],
				classData:{},
				canves_show:false,
				img_list:["https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/0354de965f84448bbff100962fd95af2.png","https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/27e633db1cac42d38f9355795bf52dfc.png","https://api.youcun.tech/epen-slice-upload/show/file/uploads/data/823dbd4c2756d7c0165ed28ce7272559/220707111922575/f3f65f00bb56459e997052b650d0e54d.png"]
			}
		},
		created() {
			
		},
		mounted() {
			this.QueryDetailById()
		},
		beforeDestroy() {
			if(this.videoStart){
				this.stopRecording()
			}
		},
		destroyed() {
			
		},
		methods: {
			//文字识别
			close(val) {
				this.canves_show = val
			},
			explain_return() {
				this.$router.back(-1)
			},
			//流停止监听
			streamStop(){
				if(this.start){
					this.$refs['screen-recording'].stopRecording((start)=>{
						this.start= start;
					});
				}
			},
			startRecording() {
				this.videoStart = true;
				this.$refs['screen-recording'].startRecording((start)=>{
					this.start= start;
				});
			
			},
			stopRecordingCallback() {
				// 如果需要下载录屏文件可加上下面代码
				let url = URL.createObjectURL(this.recorder.getBlob())
				const a = document.createElement("a");
				document.body.appendChild(a);
				a.style.display = "none";
				a.href = url;
				a.download = new Date() + ".mp4";
				a.click();
				window.URL.revokeObjectURL(url);
				//以上是下载所需代码
				this.recorder.screen.stop();
				this.recorder.destroy();
				this.recorder = null;
				this.videoStart = false;
			},
			stopRecording() {
				this.videoStart = false;
				// this.recorder.stopRecording(this.stopRecordingCallback);
				this.$refs['screen-recording'].stopRecording((start)=>{
					this.start= start;
				});
			},
			xiayiye() {
				if(this.img_list_index<this.img_list.length-1){
					this.img_list_index+=1
					this.renderComponentFun('1')
				}else{
					this.$message("没有下一页")
				}
			},
			shangyyiye() {
				if(this.img_list_index>0){
					this.img_list_index-=1
					this.renderComponentFun('1')
				}else{
					this.$message("没有上一页")
				}
			},
			renderComponentFun(data){
				this.renderComponent = false;
				this.$nextTick(() => {
				  this.renderComponent = true;
				  this.$nextTick(()=>{
					  if(data=='1'){
						  this.$refs.PointDataBgc.initialization()
					  }
				  })
				});
			},
			//当前题集信息
			QueryDetailById() {
				let data = {
					setId: this.$Download.getQuestionSet(JSON.parse(localStorage.getItem('teachQuestionSetInformation')).resourceRelationList,'questionSet'),
					// setId: 2364368931241844,
				}
				let formData = new FormData();
				for (var key in data) {
					formData.append(key, data[key]);
				}
				queryDetailById(formData).then(res => {
					this.queryDetailByIdData=res.data.result
					if(this.queryDetailByIdData.epenCodeList){
						this.img_list=this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList,'inputUrl')
						this.pageIds=this.AttributeVariableArray(this.queryDetailByIdData.epenCodeList,'pageId')
						this.classData.Image=this.img_list[this.img_list_index]
					}else{
						this.classData.Image=null
						this.pageIds=[0]
					}
					this.QueryMacList()
				})
			},
			QueryMacList(){
				let data={
					userId:JSON.parse(localStorage.getItem('teachlogin')).id
				}
				queryMacList(data).then(res=>{
					this.classItem.penMac=res.data.result[0]
					this.$refs.PointDataBgc.initialization()
				})
			},
			// arry 对象 Attribute 属性
			AttributeVariableArray(arry,Attribute){
				let images = []
				arry.forEach(item => {
					if (images.indexOf(item[Attribute]) == '-1') {
						images.push(item[Attribute])
					}
				})
				return images
			},
		}
	}
</script>

<style scoped="scoped">
	.selectmintopicindexs{
		background-color: #ecf5ff;
		 color: #409eff;
	}
	.topicSituationBase {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #071737;
	}

	.topicSituationBase>p {
		margin: 4px 0 0;
	}

	.font_size {
		font-size: 40px;
	}
	.font_size1 {
		font-size: 20px;
	}

	.container_con_htmltigan {
		font-size: 46px;
	}

	.container_con_htmltigan>>>img {
		max-width: 100%;
	}

	.explain_answer_con {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		background: rgba(9, 30, 66, 0.2);

	}

	.explain_answer_con>div {
		width: 80%;
		height: 80%;
		background-color: #FFFFFF;
		position: absolute;
		left: 10%;
		top: 10%;
		display: flex;

	}

	.explain_answer_con1 {
		width: 30%;
		text-align: left;
		padding-left: 20px;
	}

	.explain_answer_con1_Base {
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #071737;
		line-height: 28px;
		margin-top: 24px;
	}

	.explain_answer_con2 {
		width: 80%;
		text-align: left;
		padding-left: 20px;
		overflow: auto;
	}

	.container_con {
		position: relative;
		background-color: #fff;
	}

	.container_con_html {
		padding: 20px;
		height: 78vh;
		overflow: auto;
		border-radius: 10px;
	}

	.explain_Answer1 {
		padding: 0 0.75%;
		background-color: #fff;
		border-right: 1px solid #D8DEEA;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.explain_Answer2 {
		padding: 0 0.75%;
		background-color: #fff;
		border-left: 1px solid #D8DEEA;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.explain_ques {
		padding: 0 20px;
		overflow: auto;
		display: flex;
		flex-wrap: nowrap;
		background-color: #FFFFFF;
	}

	.explain_ques>div {
		margin: auto;
		padding-left: 10px;
	}


	.col-center-block {
		float: none;
		display: block;
		margin: 0 auto;
	}

	.aboust_bott20 {
		white-space: nowrap;
	}

	.aboust_top20 {
		white-space: nowrap;
	}

	.container_con1 {
		font-size: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
