<template>
	<canvas :id="className" :width="CanvesWight" :height="CanvesHeight"></canvas>
</template>

<script>
import {
	WebSocketFunc
} from '../../utils/src_utils_socket.js'
import { getdotsv3 } from '../../api/Correct.js'
import Correction from '../../utils/mycanvesjs/Correction.js'
export default {
	name: 'Class',
	data() {
		return {
			cxt: '',
			dots_data: '',
			data_result: '',
			timer2: '',
			page: '',
			ws: '',
			cur_page: '',
			className: 'classNameN',
			CanvesWight: 1033.4,
			CanvesHeight: 1461.6,
			form: {
				endX: 104,
				endY: 148,
				startX: 0,
				startY: 0,
			},
			Image: 'https://anyid-tupian.oss-cn-shanghai.aliyuncs.com/notebook/notebook2.png'
		}
	},
	mounted() {

	},
	destroyed() {
		this.getWebSocketcolse()
	},
	methods: {
		initialization(imgs) {
			let img = "https://api.youcun.tech/epen-slice-upload" + this.$parent.img_list[this.$parent.img_list_index]
			this.init_canvas(img)
		},
		init_canvas: function (src) {
			let that = this
			const canvas = document.getElementById(this.className);
			this.cxt = canvas.getContext('2d');
			var img1 = new Image();
			img1.src = src
			$('#' + this.className).drawImage({
				source: img1.src,
				layer: true,
				x: 0,
				y: 0,
				width: this.CanvesWight,
				height: this.CanvesHeight,
				fromCenter: false,
				shadowColor: '#222',
				shadowBlur: 3,
			}).drawLayers();
			that.cur_page = that.$parent.img_list_index
			let text = that.cur_page + 1
			text = "第" + text + "页"
			that.draw_score(that.CanvesWight / 2, 50, text)
			this.getdot()
			this.getWebSocketcolse()
			this.getWebSocket()

		},
		draw_score(x, y, data) {
			//分数变红
			this.$nextTick(() => {
				if (typeof (data) == 'undefined') {
					return
				}
				$('#' + this.className).removeLayer(x + 'zhangclear' + y)  //removeLayer参数只有一个，为Layer名或者Index，根据图层名或者索引移除图层；
				let score = data == '-1' ? 0 : this.hasDot(data)
				if (score || score === 0) {
					$('#' + this.className).addLayer({
						name: x + 'zhangclear' + y,   //图层名称
						method: "drawText",
						fillStyle: 'red',
						strokeStyle: 'rgba(255,255,255,0.2)',
						x: x,							//图层坐标X
						y: y ,   //图层坐标Y
						// fontSize: 24 * this.multiple / 8,
						fontSize: 24 ,
						fontFamily: 'Verdana, sans-serif',
						text: score
					}).drawLayers();
				}
			})
		},
		// 保留小数点后两位
		hasDot(num) { // 保留小数点后两位
			var result = (num.toString()).indexOf("."); // 查找是否含有小数点
			if (result != -1) {
				return (num.toString()).substring(0, result + 3) //有小数点保留两位小数
			} else {
				return num
			}
		},
		getdot() {
			if (!this.$parent.classItem.penMac) {
				return
			}
			let params = {
				data: {
					"beginTime": JSON.parse(localStorage.getItem('teachNote')).beginTime,
					"endTime": JSON.parse(localStorage.getItem('teachNote')).endTime,
					"endX": this.form.endX,
					"endY": this.form.endY,
					"startX": this.form.startX,
					"startY": this.form.startY,
					"startPageId": this.$parent.pageIds[this.$parent.img_list_index],
					"endPageId": this.$parent.pageIds[this.$parent.img_list_index],
					"penMac": this.$parent.classItem.penMac,
				}
			}
			getdotsv3(params).then(response => {
				this.dots_data = response.data.Stroke
				this.dots_draw1(this.dots_data, 10);
			});
		},
		dots_draw(data, n) {
			$("#" + this.className).draw({
				layer: true,
				fn: () => {
					let canshu = {
						cxt: this.cxt,
						data: data,
						n: n,
						pageId: this.$parent.pageIds[this.$parent.img_list_index],
					}
					Correction.actualTimedotsDraw(canshu)
				}
			}).drawLayers()
		},
		dots_draw1(data, n) {
			this.dots_draw1data += 1
			$("#" + this.className).draw({
				layer: true,
				name: 'myBox' + this.dots_draw1data,
				fn: () => {
					let canshu = {
						cxt: this.cxt,
						data: data,
						n: n,
						pageId: this.$parent.pageIds[this.$parent.img_list_index],
					}
					Correction.dotsDraw(canshu)
				}
			}).drawLayers()
		},
		getWebSocketcolse() {
			clearInterval(this.timer2)
			if (this.ws) {
				this.ws.close()
			}
		},
		getWebSocket() {
			if (this.ws) {
				this.ws.close()
			}
			let params = "KS0012" + this.$parent.classItem.penMac
			const url = 'wss://websocket.youcun.tech/wss/webSocket-link/kafkaSocket'
			WebSocketFunc(this.ws, params, url, (data) => {
				let canshu = JSON.parse(data)
				if (canshu.flag != '9') {
					data = Correction.actualTimedots(data)
					this.data_result = data.result
					this.dots_draw(data.result, '10')
				}
			}, (ws) => {
				this.ws = ws
			}, (timer) => {
				this.timer2 = timer
			})
		},
	}
}
</script>

<style></style>