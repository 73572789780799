// 历史点数据渲染  n放大倍数 deviation偏移
// 输入{data:点数据,cxt:canves,n:放大倍数,deviationX:x偏移量,deviationY:偏移量}
//输出 error 必传参数没有 success 1
function dotsDraw(canshu) {
	if (!canshu.data) {
		return 2
	}
	if (!canshu.cxt) {
		return 3
	}
	if (!canshu.n) {
		return 4
	}
	if (!canshu.deviationX) {
		canshu.deviationX = 0
	}
	if (!canshu.deviationY) {
		canshu.deviationY = 0
	}
	let initialValueStartY=canshu.initialValueStartY?canshu.initialValueStartY*canshu.n:0
	canshu.data.forEach((datasitem, datasindex) => {
		canshu.cxt.beginPath();
		canshu.cxt.lineWidth=1
		datasitem.x.forEach((datasitemitem, datasitemindex) => {
			if (datasitemindex == 0) {
				if(canshu.stutes=='二'){
					canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (datasitem.y[
						datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
				}else{
					if(canshu.pageId==datasitem.page_id[datasitemindex]){
						canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n, (datasitem.y[
							datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
					}else{
						canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (datasitem.y[
							datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
					}
				}
			} else {
				if(canshu.stutes=='二'){
					canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (datasitem.y[
						datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
				}else{
					if(canshu.pageId==datasitem.page_id[datasitemindex]){
						canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n, (datasitem.y[
							datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
					}else{
						canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (datasitem.y[
							datasitemindex] + canshu.deviationY) * canshu.n-initialValueStartY);
					}
				}
			}
		})
		canshu.cxt.stroke();
	})
	return 1
}
// 重绘 历史点数据渲染 
// 输入{data:点数据,cxt:canves,n:放大倍数,deviationX:x偏移量,deviationY:偏移量,interval：重绘系数}
//输出 error 必传参数没有 success 1
function intervaldotsDraw(canshu) {
	if (!canshu.data) {
		return 2
	}
	if (!canshu.cxt) {
		return 3
	}
	if (!canshu.n) {
		return 4
	}
	if (!canshu.deviationX) {
		canshu.deviationX = 0
	}
	if (!canshu.deviationY) {
		canshu.deviationY = 0
	}
	if (!canshu.interval) {
		return 5
	}
	let initialValueStartY=canshu.initialValueStartY?canshu.initialValueStartY*canshu.n:0
	return new Promise((resolve, reject) => {
		canshu.data.forEach((datasitem, datasindex) => {
			let timer=setTimeout(() => {
				canshu.cxt.beginPath();
				datasitem.x.forEach((datasitemitem, datasitemindex) => {
					if (datasitemindex == 0) {
						if(canshu.pageId==datasitem.page_id[0]){
							canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n, (
									datasitem.y[datasitemindex] + canshu.deviationY) *
								canshu.n-initialValueStartY);
						}else{
							if(canshu.pictureSize=='A3'){
								canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (
										datasitem.y[datasitemindex] + canshu.deviationY) *
									canshu.n-initialValueStartY);
							}
						}
					} else {
						if(canshu.pageId==datasitem.page_id[0]){
							canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n, (
									datasitem.y[datasitemindex] + canshu.deviationY) *
								canshu.n-initialValueStartY);
						}else{
							if(canshu.pictureSize=='A3'){
								canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (
										datasitem.y[datasitemindex] + canshu.deviationY) *
									canshu.n-initialValueStartY);
							}
						}
					}
				})
				canshu.cxt.stroke();
				if(canshu.data.length-1==datasindex){
					resolve()
				}
			}, canshu.interval * datasindex)
		})
	}).then(()=>{
	})
}
// 实时点数据渲染 
//输入 {data:实时点数据,cxt:canves,n:放大倍数,deviationX:x偏移量,deviationY:偏移量}
//输出 error 必传参数没有 success 1
function actualTimedotsDraw(canshu) {
	if (!canshu.data) {
		return 2
	}
	if (!canshu.cxt) {
		return 3
	}
	if (!canshu.n) {
		return 4
	}
	if (!canshu.deviationX) {
		canshu.deviationX = 0
	}
	if (!canshu.deviationY) {
		canshu.deviationY = 0
	}
	let initialValueStartY=canshu.initialValueStartY?canshu.initialValueStartY*canshu.n:0
	canshu.cxt.beginPath();
	canshu.data.x.forEach((datasitemitem, datasitemindex) => {
		if (datasitemindex == 0) {
			if(canshu.pageId==canshu.data.pageId){
				canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n, (canshu.data.y[datasitemindex] + canshu
					.deviationY) * canshu.n-initialValueStartY);
			}else{
				if(canshu.pictureSize=='A3'){
					if(canshu.nextPageId&&canshu.nextPageId==canshu.data.pageId){
						canshu.cxt.moveTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (canshu.data.y[datasitemindex] + canshu
							.deviationY) * canshu.n-initialValueStartY);
					}
				}
			}
		} else {
			if(canshu.pageId==canshu.data.pageId){
				canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n, (canshu.data.y[datasitemindex] + canshu
					.deviationY) * canshu.n-initialValueStartY);
			}else{
				if(canshu.pictureSize=='A3'){
					if(canshu.nextPageId&&canshu.nextPageId==canshu.data.pageId){
						canshu.cxt.lineTo((datasitemitem + canshu.deviationX) * canshu.n+canshu.width/2, (canshu.data.y[datasitemindex] + canshu
							.deviationY) * canshu.n-initialValueStartY);
					}
				}
			}
		}
	})
	canshu.cxt.stroke();
	return 1
}
// 实时点数据处理
// 
function actualTimedots(data) {
	data = JSON.parse(data)
	data.result = JSON.parse(data.result)
	return data
}
// 判断页面A4和A3
// 输入 var img1 = new Image();
// 输出 A3 A4
function JudgmentA3A4(img1) {
	if (img1.width > 2100 && img1.width <= 4200) {
		return "A3"
	} else {
		return "A4"
	}
}
export default {
	dotsDraw,
	actualTimedotsDraw,
	actualTimedots,
	intervaldotsDraw,
	JudgmentA3A4
}
